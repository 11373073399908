import axios from "axios";


const URL = window.location.origin

export const getOndemand = async id => {
    try {
        const req = await axios.get(`${URL}/data/mastercard.json`)
        return req.data.find(item => item.name === id)
    } catch (error) {
        console.error("getOndemand", error);
        throw new Error(error)
    }
}