import axios from "axios";

const SISAPI_URL = "https://sisapi.aden.org/api/"
const IC_ID = 104

const CONFIG = {
    headers: {
        Authorization: `c7121220-480f-497a-9d25-f6b742472e2e`
    }
}

export const sendMail = async (body) =>  {
    try {
        const { data } = await axios.post(SISAPI_URL + '/v1/acropolis/email/send', body, CONFIG)
        return data
    } catch (error) {
        throw new Error(error)
    }
}

export const registerUser = async (body) =>  {
    try {
        const { data } = await axios.post(SISAPI_URL + '/v1/acropolis/ondemand/register/incompany/' + IC_ID, body, CONFIG)
        return data
    } catch (error) {
        throw new Error(error)
    }
}