import React from 'react'
import styled from "styled-components";
import PropTypes from 'prop-types';

const Span = (props) => {

    const { fontSize, fontWeight, color, children } = props

    return (
        <SpanContent fontSize={fontSize} fontWeight={fontWeight} color={color}>{children}</SpanContent>
    )
}

Span.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string
}

export default Span

const SpanContent = styled.span`       
    color: ${p => p.color};
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight} !important;
`