import { useContext } from "react";
import { LandingContext } from "../context/Landing";


const useLanding = () => {
    const landing = useContext(LandingContext)
    // console.log('landing', landing)
    return landing
}

export default useLanding