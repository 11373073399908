import React from 'react'
import PropTypes from 'prop-types';

const Image = (props) => {

    const { src, width, height } = props

    return (
        <img src={src} width={width} height={height} alt="Landing"/>
    )
}

Image.propTypes = {
    src: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

export default Image