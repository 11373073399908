import React from 'react'
import styled from 'styled-components'
import { Carousel as Slider} from '3d-react-carousal'

const Carousel = (props) => {

    const { slides } = props

    const onSlideChange = () => null

    return (
        <ContentSlider>
            <Slider slides={slides} autoplay={true} interval={7000} onSlideChange={onSlideChange}/>
        </ContentSlider>
    )
}

export default Carousel

const ContentSlider = styled.div`
    width: 100%;
    margin: 2rem 0;
    min-height: 350px;
`