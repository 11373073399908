import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MaterialThemeProvider from './providers/Material';
import LandingDataProvider from './context/Landing';
import SnackbarProvider from 'react-simple-snackbar'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LandingDataProvider>
      <MaterialThemeProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </MaterialThemeProvider>
    </LandingDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
