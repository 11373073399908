import React from 'react'
import styled from 'styled-components'
import useCourses from '../../../hooks/useCourses'
import useLanding from '../../../hooks/useLanding'
import Span from '../../common/Span'
import Text from '../../common/Text'
import CardCarousel from './components/CardCarousel'
import Carousel from './components/Carousel'

const Courses = () => {

    const { textColor, courses } = useLanding()

    if (!courses) {
        return <div/>
    }

    const cards = courses.map(item => <CardCarousel image={item.image} name={item.name} description={item.description}/>)

    return (
        <Content>
            <Text color="#002F87" fontSize="24px" fontWeight="700">APRENDE · COMPARTE · IMPACTA</Text>
            <Text color={textColor} fontSize="48px" fontWeight="600">Accede a 5 cursos de formación <Span color={textColor} fontSize="48px" fontWeight="700">gratuitos</Span></Text>            
            <Carousel slides={cards}/>
        </Content>
    )
}

export default Courses

const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
    min-height: 350px;
`