import React from 'react'
import styled from 'styled-components'

const GridContent = (props) => {

    const { col = '1fr', height , margin, children } = props

    return (
        <Grid margin={margin} col={col} height={height}>{children}</Grid>
    )
}

export default GridContent

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${p => p.col};
    height: ${p => p.height};
    margin: ${p => p.margin}
`