import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import Image from '../../common/Image'

// IMAGES
import mc from '../../../assets/image/mc-logo-white.svg'
import aden from '../../../assets/image/aden-logo-white.svg'
import useLanding from '../../../hooks/useLanding'

const Navbar = () => {

    const { navbarColor, logoWhite } = useLanding()

    return (
        <NavContainer color={navbarColor}>
            <FlexWrapper>
                <Image src={`${window.location.origin}/static/${logoWhite}`}/>
                <Line/>
                <Image src={mc}/>
                <Line/>
                <Image src={aden}/>
            </FlexWrapper>
            {/* <Image src={logo} /> */}
            <FlexWrapper>
                <Button color='secondary' variant='outlined'>Iniciar sesión</Button>
                <Button href="#form" color='secondary' variant='contained'>Registrarme</Button>
            </FlexWrapper>
        </NavContainer>
    )
}

export default Navbar

const NavContainer = styled.nav`
    width: calc(100% - 156px);
    padding: 30px 78px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    background: ${p => p.color};
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    z-index: 9;
    @media (max-width: 768px){
        width: calc(100% - 24px);
        padding: 12px;
        height: 40px;
        align-items: center;
        img{
            width: 200px;
            height: 30px;
        }
        #navbtn{
            height: 30px;
            span{
                display: none;
            }
        }
    }
`

const FlexWrapper = styled.div`
    display: flex;
    gap: 12px;
`

const Line = styled.div`
    width: 1.5px;
    height: 100%;
    background-color: #ffffff22;
`