import React from 'react'
import styled from 'styled-components'
// import DefaultChip from '../../styled-components/DefaultChip'
// import Text from '../../styled-components/Text'
import alab from "../../../assets/image/alab-logo.svg";
// import Image from '../../styled-components/Image';
// import Button from '../../styled-components/Button';
// import Share from '../shared/Share';
import { useState } from 'react';
import Text from '../../common/Text';
import Image from '../../common/Image';
import { Button } from '@mui/material';
import Span from '../../common/Span';
import useLanding from '../../../hooks/useLanding';
import Share from '../../common/Share';
import DownloadScrom from './components/DownloadScorm';
// import EmailRequest from '../shared/EmailRequest';

const Options = (props) => {

    // const { courses, teacher } = props

    const { textColor } = useLanding()

    const [openShare, setOpenShare] = useState(false)
    const [openEmail, setOpenEmail] = useState(false)

    const onCloseShare = () => setOpenShare(false)
    const onOpenShare = () => setOpenShare(true)
    const onCloseEmail = () => setOpenEmail(false)
    const onOpenEmail = () => setOpenEmail(true)

    // if (!courses) {
    //     return <div/>
    // }

    return (
        <>
            <CenterPage>
                <Text fontSize={"48px"} color={textColor}><Span color={textColor} fontWeight="bold" fontSize={"48px"}>Tienes dos opciones</Span> de cursado</Text>
                <Text fontSize={"24px"} color={"#4F4F4F"}>Eliga la que mejor se adapte a tu empresa</Text>
            </CenterPage>
            <OptionsContainer>
                <CardOption>
                    <Text fontSize="24px" fontWeight="400">Cursa en</Text>
                    <Image width={"300px"} src={alab} />
                    <CenterText>
                        <Text fontSize="24px" fontWeight="400">
                            Podrás compartir con tus colegas 5 cursos de formación online, certificados por AcrópolisLab.
                        </Text>
                    </CenterText>
                    {/* <Text fontFamily="Lato" fontSize="16px" fontWeight="400">
                        *Tendrás tiempo de registrarte hasta el 30 de septiembre de 2022
                    </Text> */}
                    <Buttons>
                        <Button href="#form" color="primary" variant="contained">Registrarme</Button>
                        <Button onClick={onOpenShare} color="primary" variant="outlined">Invitar a cursar</Button>
                    </Buttons>
                    <Share open={openShare} onClose={onCloseShare}/>
                </CardOption>
                <CardOption>
                    <OptionCase>
                        {/* <DefaultChip title="OPCIÓN B" color="#b31d15" /> */}
                    </OptionCase>
                    <Text fontSize="24px" fontWeight="400">Cursa en</Text>
                    <CenterText>
                        <Text fontFamily="Lato" fontSize="32px" fontWeight="900">TU PLATAFORMA <br/> EDUCATIVA</Text>
                    </CenterText>
                    <CenterText>
                        <Text fontFamily="Lato" fontSize="24px" fontWeight="400">
                        ADEN entrega 5 cursos para que los insertes dentro de la plataforma educativa de tu organización. Certificados por AcrópolisLab.
                        </Text>
                    </CenterText>
                    <Text fontFamily="Lato" fontSize="16px" fontWeight="400">
                        *ADEN no brindará soporte o seguimiento personalizado
                    </Text>
                    <Buttons>
                        <Button onClick={onOpenEmail} color="primary" variant="contained">Solicitar link de descarga</Button>
                    </Buttons>
                    <DownloadScrom open={openEmail} onClose={onCloseEmail}/>
                </CardOption>
            </OptionsContainer>
        </>
    )
}

export default Options

const OptionsContainer = styled.div`
    width: 100%;
    height: auto;
    margin: 5rem 0;
    display: flex;
    align-items: center;
    gap: 36px;
    justify-content: center;
    @media (max-width: 768px){
        flex-direction: column;
        height: auto;
    }
`

const CardOption = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    width: 568px;
    height: calc(597px - 96px);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 48px 0;
    position: relative;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px){
        width: auto;
        height: auto;
        text-align: center;
        padding: 1.5rem 0;
    }
`

const OptionCase = styled.div`
    position: absolute;
    top: -12px;
    transform: scale(1.5);
`

const CenterText = styled.div`
    width: 80%;
    margin: 18px 0;
    text-align: center;
`

const Buttons = styled.div`
    display: flex;
    gap: 18px;
    margin-top: 36px;
`

const CenterPage = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
`