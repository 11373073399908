import { createContext, useEffect, useMemo, useState } from "react";
import useCourses from "../hooks/useCourses";
import useParams from "../hooks/useParams";
import { getOndemand } from "../services/api/ondemand";

export const LandingContext = createContext();

const LandingDataProvider = props => {
    const { children } = props;

    const [landingInfo, setLandingInfo] = useState(null);
    

    const { courses } = useCourses()

    const { all } = useParams()
    const [ param ] = all

    useEffect(() => {
        getDataLanding()
    }, [])

    // Traigo la data de la landing
    const getDataLanding = async () => {
        // Si no hay parametro redirijo
        if (!param) {
            window.open('https://app.acropolislab.com/auth', '_self')
        }
        
        //Request
        try {
            const req = await getOndemand(param)
            setLandingInfo(req)
        } catch (error) {
            alert("ERROR")
        }
        
    }

    // METO EN MEMORIA LA INFORMACION DE LA LANDING
    const contextLanding = useMemo(() => ( { ...landingInfo } ), [landingInfo])

    return <LandingContext.Provider value={{...contextLanding, courses}}>
        {children}
    </LandingContext.Provider>
}

export default LandingDataProvider