import { Button } from "@mui/material";
import { Formik, Form as FormFormik, Field } from "formik";
import { useState } from "react";
import styled from "styled-components";
// import { registerUser, sendMail } from "../../api/sis-api";
// import { registerSchema } from "../../schemas/auth";
// import A from "../../styled-components/A";
// import Text from "../../styled-components/Text";
// import DefaultField from "../shared/DefaultField";
import { useSnackbar } from "react-simple-snackbar";
import Text from "../../common/Text";
import { registerSchema } from "../../../schemas/auth";
import DefaultField from "../../common/InputField";
import A from "../../common/A";
import Span from "../../common/Span";
import useLanding from "../../../hooks/useLanding";
import { registerUser, sendMail } from "../../../services/api/sisapi";
// import { emailRegister, emailRegister_ANDRES_CULASSO, emailRegister_LUIS_ESKEMBRE } from "../../utils/emailTemplate";

const ADEN_URL = process.env.REACT_APP_ADEN;
const ACROPOLIS_URL = process.env.REACT_APP_ACROPOLIS;

const options = {
    style: {
        backgroundColor: '#eee',
        fontSize: '24px',
        textAlign: 'center',
    },
    closeStyle: {
        color: 'lightcoral',
        fontSize: '16px',
    },
}

const Form = (props) => {

    const initialValues = {
        name: "",
        lastName: "",
        email: "",
        password: "",
        confirmPass: "",
        term: true,
    };

    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar(options);

    const { textColor, group_id, courses } = useLanding()

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                firstname: values.name,
                lastname: values.lastName,
                email: values.email,
                password: values.password,
                courses: courses.map(course => course.id),
                // Agregar id de JSON
                group_id: group_id
            };
            const bodyUser = {
                from_email: "adencursos@aden.org",
                to_emails: [values.email],
                subject:
                    "Ingresa a la plataforma educativa de AcrópolisLab! | ADEN te acompaña",
                html_content: "Bienvenido",
            };
            await registerUser(body);
            await sendMail(bodyUser);
            setLoading(false);
            openSnackbar("Se creó correctamente el usuario");
            setTimeout(() => {
                window.open(ACROPOLIS_URL, "_self");
            }, 1000);
        } catch (error) {
            console.error(error);
            openSnackbar("Ocurrió un problema al cargar la solicitud");
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormContainer id="form">
            <Text color={textColor} fontSize="48px" fontWeight="700">
                ¡Regístrate e impacta!
            </Text>
            {/* <Text id="title-form" fontSize="18px" fontWeight="400">
                Elige la que mejor se adapte a tí
            </Text> */}
            <FormWrapper>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={registerSchema}
                >
                    {({ setFieldValue, values }) => (
                        <FormF>
                            <Col>
                                <DefaultField
                                    name="name"
                                    type="text"
                                    label="Nombre"
                                    variant="outlined"
                                    required
                                />
                                <DefaultField
                                    name="lastName"
                                    type="text"
                                    label="Apellido"
                                    variant="outlined"
                                    required
                                />
                            </Col>
                            {/** EMAIL */}
                            <DefaultField
                                name="email"
                                type="text"
                                label="Correo electrónico"
                                variant="outlined"
                                onChange={(e) =>
                                    setFieldValue(
                                        "email",
                                        e.target.value.toLowerCase()
                                    )
                                }
                                values={values.email}
                                required
                            />
                            <DefaultField
                                name="password"
                                type="password"
                                label="Contraseña"
                                variant="outlined"
                                required
                            />
                            <DefaultField
                                name="confirmPass"
                                type="password"
                                label="Repetir contraseña"
                                variant="outlined"
                                required
                            />
                            <CenterMessage>
                                <Field required type="checkbox" name="term" />
                                <Span
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    Acepto los{" "}
                                    <A
                                        target={"_blank"}
                                        fontSize="16px"
                                        fontWeight="400"
                                        color={textColor}
                                        href={ADEN_URL}
                                    >
                                        Términos y Condiciones
                                    </A>
                                </Span>
                            </CenterMessage>
                            <Button
                                // disabled={true}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {!loading ? "Registrarme" : "Cargando"}
                            </Button>
                            <CenterMessage>
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    ¿Ya creaste tu cuenta?{" "}
                                </Text>
                                <A
                                    target={"_blank"}
                                    fontSize="16px"
                                    fontWeight="900"
                                    fontFamily="Lato"
                                    color={textColor}
                                    href={ACROPOLIS_URL}
                                >
                                    Iniciar sesión
                                </A>
                            </CenterMessage>
                        </FormF>
                    )}
                </Formik>
            </FormWrapper>
        </FormContainer>
    );
};

export default Form;

const FormContainer = styled.div`
    width: 100%;
    height: auto;
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 2rem 0;
    /* z-index: 9999; */
    @media (max-width: 768px) {
        #title-form {
            text-align: center;
            font-size: 24px;
        }
    }
`;

const FormWrapper = styled.div`
    width: 497px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    border-radius: 20px;
    @media (max-width: 768px) {
        width: auto;
    }
`;

const FormF = styled(FormFormik)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px auto;
    width: 90%;
    #submit {
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`;

const Col = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`;

const CenterMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;
