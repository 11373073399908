import * as yup from 'yup';

export const requestScorm = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(256, 'Máximo 256 caracteres')
        .required('Nombre requerido'),
    business: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(128, 'Máximo 128 caracteres')
        .required('Empresa requerida'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo electrónico requerido')
        .max(320, 'Máximo 320 caracteres'),
});