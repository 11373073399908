import React from 'react'
import Banner from '../components/shared/Banner/Banner'
import Collaborators from '../components/shared/Collaborators/Collaborators'
import Courses from '../components/shared/Courses/Courses'
import Footer from '../components/shared/Footer/Footer'
import Form from '../components/shared/Form/Form'
import Navbar from '../components/shared/Navbar/Navbar'
import Options from '../components/shared/Options/Options'

const Landing = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <Collaborators />
            <Courses />
            <Options />
            <Form />
            <Footer />
        </>
    )
}

export default Landing