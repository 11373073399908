import { useEffect, useState } from "react"

const REPO_URL = 'https://repositorio-api.aden.org/api'
const VERSION = 'v1'
const IC_ID = 104

const useCourses = (coursesParams) => {

    const [courses, setCourses] = useState(null)

    useEffect(() => {
        getCourses()
    }, [])

    const getCourses = async () => {
        try {
            const data = await fetch(`${REPO_URL}/${VERSION}/repo_aden/ondemand/courses/own_by_ondemand/${IC_ID}`)
            const { response_data } = await data.json()
            // let result = []
            // response_data.forEach(course => {
            //     const isExist = coursesParams.some(c => c === course.id)
            //     if (isExist) {
            //         result = [...result, course]
            //     }
            // });
            setCourses(response_data)
        } catch (error) {
            setCourses(false)
        }
    }


    return { courses }
}

export default useCourses