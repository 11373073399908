
const useParams = () => {

    const rule = '/'
    const regex = RegExp(rule, "")
    const all = window.location.pathname.replace(regex, '')?.split("/") || false

    const findPath = (search) => all.find(item => search === item)

    return { all, findPath }
}

export default useParams