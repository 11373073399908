import React from 'react'
import styled from 'styled-components'
import Image from '../../common/Image'
import Text from '../../common/Text'
import alab from "../../../assets/image/alab-vertical.svg";
import mc from "../../../assets/image/mc-logo-color.svg";
import bcr from "../../../assets/image/bcr-color.svg";
import aden from "../../../assets/image/aden-color.svg";
import useLanding from '../../../hooks/useLanding';



const Collaborators = () => {

    const { logo } = useLanding()

    return (
        <Content>
            <Text color="#A8A8A8" fontSize="18px" fontWeight="700">COLABORAN</Text>
            <Marks>
                <Image src={`${window.location.origin}/static/${logo}`} width="220px"/>
                <Image src={mc} width="100px"/>
                <Image src={aden} width="160px"/>
                <Image src={alab} width="210px"/>
            </Marks>
        </Content>
    )
}

export default Collaborators

const Content = styled.div`
    background-color: #F9F9F9;
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
`

const Marks = styled.div`
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin-top: 48px;
`