import React from 'react'
import styled from "styled-components";
import GridContent from '../../common/GridContent';
import banner from "../../../assets/image/banner.jpg";
import Image from '../../common/Image';
import Text from '../../common/Text';
import { Button } from '@mui/material';
import useLanding from '../../../hooks/useLanding';

const Banner = () => {

    const { textColor, title } = useLanding()

    return (
        <GridContent col="1.1fr 1fr" height="calc(100vh - 96px)" margin="96px 0 0 0">
            <Content>
                <Text fontSize="64px" fontWeight="700" color={textColor} >Inspiramos el desarrollo de tu PyME</Text>
                <Text>Mastercard y ADEN se unen a {title} para brindarte <b>cursos de formación gratuitos</b> que aportarán al desarrollo de tu pequeña y mediana empresa.</Text>
                <Buttons>
                    <Button color="primary" variant='contained'>Conocer más</Button>
                    <Button href='#form' color="primary">Registrarme</Button>
                </Buttons>
            </Content>
            <ImageContent>
                <Image width="100%" height="100%" src={banner}/>
            </ImageContent>
        </GridContent>
    )
}

export default Banner

const ImageContent = styled.div`
    width: 100%;
    height: calc(100vh - 96px);
    img{
        object-fit: cover;
    }
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
`

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
`